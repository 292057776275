$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "~bootstrap/scss/bootstrap";

dl {
    @extend .row;
}
dt {
    @extend .col-12;
    @extend .col-md-4;
    @extend .text-end;
}
dd {
    @extend .col-12;
    @extend .col-md-8;
    margin-bottom: 0;
}
